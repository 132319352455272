import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/browser';

/**
 * @summary Initialize Hotjar
 */
export const hotjarInit = () => {
  const siteId = 5237622;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);
}

/**
 * @summary Fire Hotjar event
 * @param {string} eventName - The event name
 * @returns {void}
 */
export const fireHotjarEvent = (eventName) => {
  return Hotjar.event(eventName);
}

/**
 * @summary Set Hotjar platform tags
 * @param {object} appMeta - The app meta object
 */
export const setHotjarPlatformTags = (appMeta) => {
  try {
    for (const [key, value] of Object.entries(appMeta)) {
      if (value) {
        fireHotjarEvent(key);
      }
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        action: 'Error: setHotjarPlatformTags Action'
      }
    });
  }
}

